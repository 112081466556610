<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>系统</el-breadcrumb-item>
          <el-breadcrumb-item>广告管理</el-breadcrumb-item>
          <el-breadcrumb-item>推广商家</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="所属区域">
              <el-select
                v-model="where.operationAreaCode"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="投放范围">
              <el-select
                v-model="where.operationAreaCode"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="where.operationAreaCode"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="开启" value="1"></el-option>
                <el-option label="关闭" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                v-model="where.keyWord"
                placeholder="请输入订单编号/会员账号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="加入时间">
              <el-date-picker
                v-model="date"
                @change="orderDate" @input="immediUpdate"
                type="daterange"
                range-separator="~"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                                start-placeholder="开始"
                end-placeholder="截止"
              >
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button type="primary" @click="add" icon="el-icon-plus"
                >新增</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="id" label="广告ID"></el-table-column>
          <el-table-column prop="id" label="商家名称"></el-table-column>
          <el-table-column prop="id" label="门店名称"></el-table-column>
          <el-table-column prop="id" label="所属区域"></el-table-column>
          <el-table-column prop="id" label="投放范围"></el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            :render-header="renderHeader"
            width="100px"
          >
            <template slot-scope="scope">
              <el-input-number
                size="mini"
                placeholder="请输入排序"
                controls-position="right"
                style="width: 70px; text-align: left"
                v-model="list[scope.$index].sort"
                @change="setState(scope.row)"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="开始时间"></el-table-column>
          <el-table-column prop="id" label="结束时间"></el-table-column>
          <el-table-column prop="deviceType" label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isShow"
                @change="setState(scope.row)"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="2"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="创建人"></el-table-column>
          <el-table-column prop="id" label="创建时间"></el-table-column>
          <el-table-column label="操作" fixed="right" width="100px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link"
                  >操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    ><el-button
                      type="text"
                      class="el-icon-s-shop"
                      size="medium"
                      @click="
                        $router.push({
                          name: 'mpadd',
                          query: { id: scope.row.id },
                        })
                      "
                      >详情</el-button
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><el-button
                      type="text"
                      class="el-icon-delete"
                      size="medium"
                      @click="deleteBtn(scope.row)"
                      >删除</el-button
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      list: [],
      date: "",
      row: {},
      loading: false,
      dialogEditVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      rolesData: [],
      selectId: "",
      where: {
        page: 0,
        limit: 0,
      },
    };
  },
  methods: {
    listData() {
      this.loading = true;
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.$get("this.$api.cityList", this.where).then((res) => {
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDate = '';
          this.where.endDate = '';
        }
      });
    },
    getDialog(val) {
      this.dialogEditVisible = val;
    },
    add() {
      this.$router.push({ name: "mpadd" });
    },
    deleteBtn(data) {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.post("this.$api.cityDel", { cityId: data.id });
          this.listData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    setState(data) {
      this.post("this.$api.cityUpdate", {
        id: data.id,
        isShow: data.isShow,
        sort: data.sort,
      });
    },
    post(url, data) {
      this.$postFormData(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.listData();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    // render 事件
    renderHeader(h, { column }) {
      let text = "数字越小 排序越高";
      return h("div", [
        h("span", column.label),
        h("el-tooltip", [
          h("i", {
            class: "el-icon-question",
            style: "color:#409eff;margin-left:5px;",
          }),
          h(
            "div",
            {
              slot: "content",
              // style:"width: 200px;"
            },
            text
          ),
        ]),
      ]);
    },
  },
  created() {
    // this.listData();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input-number.is-controls-right .el-input__inner {
  padding-left: 2px;
  padding-right: 32px;
}
</style>
